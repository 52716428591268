import * as yup from "yup";
import generalTabSchema from "./Validation/generalTabSchema";
import productSchema from "./Validation/productSchema";
import {
  addressSchema,
  customerSchema,
  customerEditSchema,
  customerCreditSchema,
  creditSchema,
  customerTermSchema,
  customerEmployeeSchema,
  customerDocumentSchema,
  customerTypeSchema,
  customerJobTypeSchema,
} from "./Validation/customer";
import { employeeSchema, employeeEditSchema } from "./Validation/employee";
import { resetPasswordSchema } from "./Validation/auth";

// Product Form
const productsTabSchema = yup.object().shape({
  unit_id: yup.array(),
  supplier_details: yup.array().of(
    yup.lazy((value) => {
      // Create a dynamic object to hold the validation schema
      let validationObject = {};

      // Assuming unit_id is an array containing unit names like ["Box", "Each", ...]
      value?.units?.forEach((unitName) => {
        validationObject[unitName] = yup.array().of(
          yup.object().shape({
            quantity: yup.mixed().test("is-number", "Required", (value) => {
              if (value === undefined || value === null || value === "") {
                return false;
              }
              return !isNaN(value);
            }),
            cost: yup.mixed().test("is-number", "Required", (value) => {
              if (value === undefined || value === null || value === "") {
                return false;
              }
              return !isNaN(value);
            }),
            regular_percentage: yup
              .mixed()
              .test("is-number", "Required", (value) => {
                if (value === undefined || value === null || value === "") {
                  return false;
                }
                return !isNaN(value);
              }),
          })
        );
      });
      const notNaN = (value) => !isNaN(value);
      return yup.object().shape({
        supplier_id: yup
          .string()
          .required("Required")
          .test("is-not-nan", " Required", notNaN),
        mfg_id: yup
          .mixed()
          .test("is-same-or-not", "Not Same", function (value) {
            if (
              (value &&
                value === this.parent.supplier_sku &&
                this.parent.supplier_sku) ||
              (value === this.parent.upc_code && this.parent.upc_code)
            ) {
              return false;
            }
            return true;
          }),
        supplier_sku: yup
          .mixed()
          .test("is-same-or-not-two", "Not Same", function (value) {
            if (
              (value && value === this.parent.mfg_id && this.parent.mfg_id) ||
              (value === this.parent.upc_code && this.parent.upc_code)
            ) {
              return false;
            }
            return true;
          }),
        upc_code: yup
          .mixed()
          .test("is-same-or-not-one", "Not Same", function (value) {
            if (
              (value &&
                value === this.parent.supplier_sku &&
                this.parent.supplier_sku) ||
              (value === this.parent.mfg_id && this.parent.mfg_id)
            ) {
              return false;
            }
            return true;
          }),
        ...validationObject, // Spread the dynamic validation rules
      });
    })
    // supplier_cost: yup.string().required("Required"),
    // unit_price: yup.string().required("Required"),
    // regular_price: yup.string().required("Required"),
    // })
  ),

  main_supplier_id: yup.string().required("Required"),
  primarycost: yup.object().shape({
    name: yup.string().required("Unit Required"),
    // cost: yup.number().required("Cost Required"),
  }),
});

// category Form
const categorySchema = yup.object().shape({
  label: yup.string().required("Required"),
  website_id: yup.mixed().required("Required"),
});

// Supplier Form
const supplierSchema = yup.object().shape({
  company_name: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  // last_name: yup.string().required("Required"),
  product_category: yup
    .array()
    .test("required-if-inventory", "Required", function (value) {
      return value?.length > 0 && !!value;
    }),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  regular_percentage: yup.string().required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  // unit: yup.string().required("Required"),
});

// Store Form
const storeSettingsFSchema = yup.object().shape({
  website_name: yup.string().required("Required"),
  website_id: yup
    .mixed()
    .test("is-number", "Required", (value) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }
      return !isNaN(value);
    })
    .required("Required"),
  website_endpoint: yup.string().required("Required").url("Enter Valid URL"),
  contact_email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  phone: yup
    .string()
    .required("Required")
    .test("is-valid", "Enter Valid Phone Number", (value) => {
      const numericValue = parseInt(value.split("-").join(""));
      return !isNaN(numericValue) && numericValue.toString().length === 10;
    }),
  website_url: yup.string().required("Required").url("Enter Valid URL"),
  website_prefix: yup.string().required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
});

// PurchaseOrderForm
const purchaseOrderSchema = yup.object().shape({
  supplier_id: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
  created_by: yup.string().required("Required"),
  po_number: yup.string().required("Required"),
  confirm_by: yup.mixed().when(["approve_by"], {
    is: (approve_by) => approve_by,
    then: () => yup.string().required("Required"),
    otherwise: () => yup.mixed().notRequired(),
  }),
  delivery_type: yup.string().required("Required"),
  address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  product_details: yup.array().of(
    yup.object().shape({
      quantity: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
    })
  ),
});
// tax popup form
const taxFormSchema = yup.object().shape({
  tax_name: yup.string().required("Required"),
  tax_amount: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
});

// approve form
const approveOrderSchema = yup.object().shape({
  confirm_by: yup.string().required("Required"),
});

// Cancel purchase order
const cancelPurchaseOrderSchema = yup.object().shape({
  reason_for_cancel: yup.string().required("Required"),
  selectedRows: yup
    .object()
    .test("required-if", "Please select row for cancel", function (value) {
      return value.selectedCount > 0;
    }),
});

// Recieved Po
const recievedPoSchema = yup.object().shape({
  verified_by: yup.string().required("Required"),
});

const verifyOTPSchema = yup.object().shape({
  otp: yup.string().required("Required"),
});

const priceCategorySchema = yup.object().shape({
  categories: yup.array().of(
    yup.object().shape({
      percentage: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
      rebate_percentage: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
      category_id: yup.number().required("Required"),
    })
  ),
});


const SplitPaymentSchema = yup.object().shape({});
//refund
//Employee Form

// Promotion Form
const PromotionGeneralSchema = yup.object().shape({
  name: yup.string().required("Required"),
  start_date: yup.string().required("Required"),
  end_date: yup.string().required("Required"),
});
//Promotion Product
const PromotionProductSchema = yup.object().shape({
  product: yup.array().of(
    yup.object().shape({
      percentage: yup
        .mixed()
        .test("required-if", "must be less than 100", function (value) {
          if (value > 100) {
            return false;
          }
          return !isNaN(value);
        })

        .test("is-number", "Required", (value) => {
          if (
            value === undefined ||
            value === null ||
            value === "" ||
            value > 100
          ) {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),

      product_id: yup.number().required("Required"),
    })
  ),
});
//customer payment form
const PaymentSchema = yup.object().shape({
  number: yup
    .string()
    .required("Required")
    .matches(/^\d{16}$/, "Card number must be 16 digits"),

  name: yup.string().required("Required"),
  exp_date: yup
    .string()
    .required("Required")
    .matches(
      /^(0[1-9]|1[0-2])\/(2[3-9]|[3-9][0-9])$/,
      "Invalid expiration date. Example: MM/YY"
    ),
  cvc: yup
    .string()
    .required("Required")
    .matches(/^\d{3}$/, "Invalid CVC."),
  postal_code: yup.string()
    .required("Required")
    .matches(/^[A-Za-z0-9]{6}$/, "Postal code must be 6 characters long."),
});
const priceGroupGenralSchema = yup.object().shape({
  store_id: yup.array().required("Required"),
  percentage: yup
    .mixed()
    .test("is-number", "Required", (value) => {
      if (value === undefined || value === null || value === "") {
        return false;
      }
      return !isNaN(value);
    })
    .required("Required"),
  customer_type: yup.number().required("Required"),
  name: yup.string().required("Required"),
});
const priceGroupProductSchema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      percentage: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
      price: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
      product_id: yup.number().required("Required"),
    })
  ),
});

const sendEmailSchema = yup.object().shape({
  customer_name: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  subject: yup.string().required("Required"),
  send_by: yup.string().required("Required"),
});
const ctsairpaySchema = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+(.[A-Z]{2,4}){1,2}$/i,
      "Enter Valid Email"
    ),
  amount: yup.string().required("Required"),
});
//  cardAmountSchema Form
const cardAmountSchema = yup.object().shape({
  amount: yup
    .string()
    .required("Required")
    .test(
      "is-valid",
      "The amount must be greater than or equal to the minimum charge",
      function (value) {
        if (value < 1) {
          return this.createError({
            message: `The amount must be greater than or equal to the minimum charge`,
          });
        }
        return true;
      }
    ),
});
//order preparation
const orderPreparationSchema = yup.object().shape({
  verify_user: yup.string().required("Required"),
  selectedRows: yup
    .object()
    .test("required-if", "Selected row is required", function (value) {
      return value.selectedCount > 0;
    }),
});
// RETURN ORDER
const orderrReturnSchema = yup.object().shape({
  selectedRows: yup
    .object()
    .test("required-if", "Selected row is required", function (value) {
      return value.selectedCount > 0;
    }),
  reason: yup.string().required("Required"),
  receive_by: yup.string().required("Required"),
});
//order preparation
const orderAdditionalInfoSchema = yup.object().shape({
  shipping_method: yup.string().required("Required"),
  // shipping_address: yup
  //   .number()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     console.log(this.parent.shipping_method, value, "shipping_method");
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  // delivery_type: yup
  //   .string()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  // ship_via: yup
  //   .number()
  //   .test("required-if-inventory", "Required", function (value) {
  //     const isInventory = this.parent.shipping_method;
  //     return isInventory !== "Store pickup" ? !!value : true;
  //   }),
  billing_address: yup.number().required("Required"),
  delivery_date: yup.string().required("Required"),
  order_taken_by: yup.string().required("Required"),
});
// Order Payment
const orderPaymentTabSchema = yup.object().shape({
  payment: yup.array().of(
    yup.object().shape({
      payment_type: yup.string(),
      over_write: yup.boolean(),
      amount: yup.mixed().test("is-number", "Required", (value) => {
        if (value === undefined || value === null) {
          return false;
        }
        return !isNaN(value);
      }),
      payment_details: yup.mixed().when(["payment_type", "amount"], {
        is: (payment_type, amount) =>
          amount > 0.0 &&
          ["cheque", "email", "ctsairpay"].includes(payment_type),
        then: () => yup.string().required("Payment Details is Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      // payment_method: yup.string().when(["payment_type", "amount"], {
      //   is: (payment_type, amount) =>
      //     amount > 0.0 && ["card"].includes(payment_type),
      //   then: () => yup.string().required("Required"),
      //   otherwise: () => yup.mixed().notRequired(),
      // }),
      payment_method: yup.string().when(["payment_type", "amount", "payment_card_type", "validationcondition"], {
        is: (payment_type, amount, payment_card_type, validationcondition) => {

          // const conditionMet =
          //   payment_card_type === "interac_present" &&
          //   payment_type === "card" &&
          //   amount > 0.0;
          // return conditionMet;
          let conditionMet = false;
          if (validationcondition === false) {
            conditionMet = payment_card_type === "interac_present" && payment_type === "card" && amount > 0.0;
          } else if (validationcondition === true) {
            conditionMet = payment_type === "card" && amount > 0.0;
          }
          return conditionMet;
        },
        then: () => yup.string().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      over_write_by: yup
        .string()
        .when(["payment_type", "amount", "over_write"], {
          is: (payment_type, amount, over_write) =>
            amount > 0.0 && over_write && ["term"].includes(payment_type),
          then: () => yup.string().required("Required"),
          otherwise: () => yup.mixed().notRequired(),
        }),

      authorized_person: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      credit_term: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
      code: yup.number().when(["payment_type", "amount"], {
        is: (payment_type, amount) => {
          const condition = amount > 0.0 && payment_type === "temp_term";
          return condition;
        },
        then: () => yup.number().required("Required"),
        otherwise: () => yup.mixed().notRequired(),
      }),
    })
  ),
});
// PurchaseOrderForm
const billFormSchema = yup.object().shape({
  supplier_id: yup.mixed().test("is-number", "Required", (value) => {
    if (value === undefined || value === null || value === "") {
      return false;
    }
    return !isNaN(value);
  }),
  // ship_via: yup.number().required("Required"),
  // buying_group: yup.number().required("Required"),
  terms: yup.string().required("Required"),
  delivery_type: yup.string().required("Required"),
  shipping_address: yup
    .mixed()
    .required("Required")
    .test(
      "is-address-selected",
      "Please select from the suggestions",
      (value) => {
        return value !== true;
      }
    )
    .strict(),
  product_details: yup.array().of(
    yup.object().shape({
      quantity: yup
        .mixed()
        .test("is-number", "Required", (value) => {
          if (value === undefined || value === null || value === "") {
            return false;
          }
          return !isNaN(value);
        })
        .required("Required"),
    })
  ),
});

// const PriceGroupSchema = yup.object().shape({
//   selectedRows: yup
//     .object()
//     .test("required-if", "Selected row is required", function (value) {
//       return value.selectedCount > 0;
//     }),
//   reason: yup.string().required("Required"),
//   receive_by: yup.string().required("Required"),
// });

// Kitchen Template Validation
const kitchenTemplateSchema = yup.object().shape({
  slug: yup.string().required("Please Select Slug Corresponds To Kitchen Template")
});

// DyWall Template Validation 
const drywallTemplateSchema = yup.object().shape({
  slug: yup.string().required("Please Select Slug Corresponds To DryWall Template")
})


export {
  generalTabSchema,
  cancelPurchaseOrderSchema,
  purchaseOrderSchema,
  taxFormSchema,
  approveOrderSchema,
  productsTabSchema,
  categorySchema,
  supplierSchema,
  storeSettingsFSchema,
  recievedPoSchema,
  priceCategorySchema,
  customerSchema,
  customerTypeSchema,
  customerJobTypeSchema,
  PromotionProductSchema,
  PromotionGeneralSchema,
  priceGroupProductSchema,
  priceGroupGenralSchema,
  sendEmailSchema,
  addressSchema,
  ctsairpaySchema,
  orderPreparationSchema,
  orderAdditionalInfoSchema,
  customerEditSchema,
  cardAmountSchema,
  customerEmployeeSchema,
  customerDocumentSchema,
  PaymentSchema,
  creditSchema,
  orderrReturnSchema,
  customerTermSchema,
  SplitPaymentSchema,
  customerCreditSchema,
  orderPaymentTabSchema,
  employeeSchema,
  employeeEditSchema,
  billFormSchema,
  productSchema,
  resetPasswordSchema,
  verifyOTPSchema,
  kitchenTemplateSchema,
  drywallTemplateSchema
};
