import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Layout from "./components/Layout";
import { Loader, LoadingOverlay } from "@mantine/core";
import { lazyWithRetry } from "helpers/Golbal";
import { NotFound } from "pages/NotFound/NotFound.jsx";
import OrderCanceView from "pages/menu/order-management/cancel-order/view-show";


const CustomerRoutes = lazyWithRetry(() => import("route/customerRoute"));
const EmployeeRoutes = lazyWithRetry(() => import("route/employeeRoute"));
const OrderRoutes = lazyWithRetry(() => import("route/orderRoute"));
const PriceRoutes = lazyWithRetry(() => import("route/priceRoute"));
const ProductRoutes = lazyWithRetry(() => import("route/productRoute"));
const SettingRoutes = lazyWithRetry(() => import("route/settingRoute"));
const SupplierRoutes = lazyWithRetry(() => import("route/supplierRoute"));
const TempRoutes = lazyWithRetry(() => import("route/tempRoute"));
const InventoryRoutes = lazyWithRetry(() => import("route/inventoryRoute"));
const PurchaseRoutes = lazyWithRetry(() => import("route/purchaserderRoute"));
const CategoryRoutes = lazyWithRetry(() => import("route/categoryRoute"));
const BrandRoutes = lazyWithRetry(() => import("route/brandRoute"));
const SeoMetaRoutes = lazyWithRetry(() => import("route/seometaRoute"));

const PricingGroupPromotion = lazyWithRetry(() => import("pages/menu/price-management/promotion"));
const CustomLogin = lazyWithRetry(() => import("pages/authentication/CustomLogin"));
const ForgetPassword = lazyWithRetry(() => import("pages/authentication/ForgotPassword"));
const ResetPassword = lazyWithRetry(() => import("pages/authentication/ResetPassword"));
const DashBoard = lazyWithRetry(() => import("pages/dashboard"));
const BillRoutes = lazyWithRetry(() => import("route/billRoute"));
const LumberCalculator = lazyWithRetry(() => import("pages/lumber-calculator/create"));
const OrderCreatePos = lazyWithRetry(() => import("pages/pos"));
const ImportRoutes = lazyWithRetry(() => import("route/importRoute"));
const Catalogue = lazyWithRetry(() => import("pages/catalogue"));
const PaymentSuccess = lazyWithRetry(() => import("pages/menu/customer-management/receivablePayment/PaymentSuccess"));
const PaymentFailure = lazyWithRetry(() => import("pages/menu/customer-management/receivablePayment/PaymentFailure"));
const MonerisPayment = lazyWithRetry(() => import("pages/authentication/MonerisPayment"));

const VehicleEdit = lazyWithRetry(() => import("pages/vehicle/edit"));
const VehicleList = lazyWithRetry(() => import("pages/vehicle/list"));
const VehicleCreate = lazyWithRetry(() => import("pages/vehicle/create"));
const VehicleView = lazyWithRetry(() => import("pages/vehicle/view"));

const CreateRole = lazyWithRetry(() => import("pages/settings/role/create"));
const EditRole = lazyWithRetry(() => import("pages/settings/role/edit"));
const RoleList = lazyWithRetry(() => import("pages/settings/role/list"));


// report route start
const InvoiceList = lazyWithRetry(() => import("pages/report/invoice/list"));
const PackingSlipList = lazyWithRetry(() => import("pages/report/packingslip/list"));
const CustomerSaleList = lazyWithRetry(() => import("pages/report/financial/list/customerSalesSummary"));
const PaymentmethodTotalList = lazyWithRetry(() => import("pages/report/financial/list/paymtdTotalSummary"));
const ProductSaleList = lazyWithRetry(() => import("pages/report/financial/list/productSalesSummary"));
const ReceivableList = lazyWithRetry(() => import("pages/report/financial/list/receivableReport"));
// const SaleList = lazyWithRetry(() => import("pages/report/financial/list/salesReport"));
const SaleOneList = lazyWithRetry(() => import("pages/report/financial/list/saleOneReport"));
const StoreCreditList = lazyWithRetry(() => import("pages/report/financial/list/storeCreditSummary"));
const TaxList = lazyWithRetry(() => import("pages/report/financial/list/taxSummary"));
const TransactionList = lazyWithRetry(() => import("pages/report/financial/list/transactionSummary"));
const MasterDataList = lazyWithRetry(() => import("pages/report/customer/list/masterData"));
const ReceivableLists = lazyWithRetry(() => import("pages/report/customer/list/receivableDetail"));
const ReceivableSummary = lazyWithRetry(() => import("pages/report/customer/list/receivableSummary"));
const InventoryList = lazyWithRetry(() => import("pages/report/inventory/list/inventorySummary"));
const LowStockOrderList = lazyWithRetry(() => import("pages/report/inventory/list/lowstockorder"));
const TopSellingProductList = lazyWithRetry(() => import("pages/report/inventory/list/topsellingproduct"));
const PromotionSaleList = lazyWithRetry(() => import("pages/report/promotion/list/promotionsale"));
const POSTransactionList = lazyWithRetry(() => import("pages/report/pos/transactionSummary"));
const POSSaleList = lazyWithRetry(() => import("pages/report/pos/saleSummary"));
const POSCurrentCashList = lazyWithRetry(() => import("pages/report/pos/currentCashDetails"));
const POSCashInCashOut = lazyWithRetry(() => import("pages/report/pos/cashInCashOut"));
const POSTotalCurrentList = lazyWithRetry(() => import("pages/report/pos/currentTotalSummary"));
const POSAllTransaction = lazyWithRetry(() => import("pages/report/pos/transactionAllSummary"));
// report route end


function App() {
  return (
    <Suspense
      fallback={
        <LoadingOverlay
          visible={true}
          loader={<Loader variant="bars" />}
          overlayBlur={1}
        />
      }
    >
      <Router>
        <Routes>
          {/* Auth Route */}
          <Route path="/login" element={<CustomLogin />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/failure" element={<PaymentFailure />} />
          <Route path="/moneris-payment" element={<MonerisPayment />} />
          {/* Acess needed */}
          <Route element={<Layout />}>
            <Route path="/" element={<DashBoard />} />
            <Route path="/menu/inventory/*" element={<InventoryRoutes />} />
            <Route path="/menu/*" element={<CustomerRoutes />} />
            <Route path="/menu/employee/*" element={<EmployeeRoutes />} />
            <Route path="/menu/order/*" element={<OrderRoutes />} />
            <Route path="/menu/pricing-group/*" element={<PriceRoutes />} />
            <Route path="/settings/*" element={<SettingRoutes />} />
            <Route path="/menu/temp/*" element={<TempRoutes />} />
            <Route path="/menu/purchase-order/*" element={<PurchaseRoutes />} />
            <Route path="/menu/supplier/*" element={<SupplierRoutes />} />
            <Route path="/menu/promotion" element={<PricingGroupPromotion />} />
            <Route path="/menu/categories/*" element={<CategoryRoutes />} />
            <Route path="/menu/brand/*" element={<BrandRoutes />} />
            <Route path="/menu/product/*" element={<ProductRoutes />} />
            <Route path="/bill-management/*" element={<BillRoutes />} />
            <Route path="/lumber-calculator" element={<LumberCalculator />} />
            <Route path="/menu/import/*" element={<ImportRoutes />} />
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/vehicle/add" element={<VehicleCreate />} />
            <Route path="/vehicle/edit/:id" element={<VehicleEdit />} />
            <Route path="/vehicle/view/:id" element={<VehicleView />} />
            <Route path="/vehicle/list" element={<VehicleList />} />
            <Route path="/role/add" element={<CreateRole />} />
            <Route path="/role/edit/:id" element={<EditRole />} />
            <Route path="/role/list" element={<RoleList />} />
            <Route path="/cancelshow-view/:id" element={<OrderCanceView />} />

            <Route path="/report/invoice-list" element={<InvoiceList />} />
            <Route path="/report/packingslip-list" element={<PackingSlipList />} />
            <Route path="/report/customersale-list" element={<CustomerSaleList />} />
            <Route path="/report/paymentmtd-list" element={<PaymentmethodTotalList />} />
            <Route path="/report/productsale-list" element={<ProductSaleList />} />
            <Route path="/report/receivable-list" element={<ReceivableList />} />
            {/* <Route path="/report/sale-list" element={<SaleList />} /> */}
            <Route path="/report/sale-list" element={<SaleOneList />} />
            <Route path="/report/storecredit-list" element={<StoreCreditList />} />
            <Route path="/report/tax-list" element={<TaxList />} />
            <Route path="/report/transaction-list" element={<TransactionList />} />
            <Route path="/report/inventory-list" element={<InventoryList />} />
            <Route path="/report/lowstockorder-list" element={<LowStockOrderList />} />
            <Route path="/report/topsellingproduct-list" element={<TopSellingProductList />} />
            <Route path="/report/promotionsale-list" element={<PromotionSaleList />} />
            <Route path="/report/masterData-list" element={<MasterDataList />} />
            <Route path="/report/summary-list" element={<ReceivableSummary />} />
            <Route path="/report/aging-list" element={<ReceivableLists />} />
            <Route path="/report/pos-devicesummary" element={<POSSaleList />} />
            <Route path="/report/pos-transaction" element={<POSTransactionList />} />
            <Route path="/report/pos-currentcash" element={<POSCurrentCashList />} />
            <Route path="/report/pos-cashincashout" element={<POSCashInCashOut />} />
            <Route path="/report/pos-totalcurrentcash" element={<POSTotalCurrentList />} />
            <Route path="/report/pos-totaltransaction" element={<POSAllTransaction />} />

            <Route path="/settings/seo-meta/*" element={<SeoMetaRoutes />} />

            <Route path="*" element={<Navigate to={"/404"} replace />} />
            <Route path="/404" element={<NotFound />} />
          </Route>

          <Route path="/pos" element={<OrderCreatePos />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to={"/404"} replace />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
